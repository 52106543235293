<template>
  <div class="neiBg">
	  <InPageImage></InPageImage>
    <div class="wrapper baseContent">
      <Breadcrumb :crumbsList="pathList"></Breadcrumb>
    </div>
	<div class="newsMenu flex align-center justify-center">
		<div @click="goOne('20230731153536012703283060937415')" :class="kindId=='20230731153536012703283060937415'?'newsMenuItem checked':'newsMenuItem'">常见问题</div>
		<div @click="goOne('20230731153554051645033698210800')" :class="kindId=='20230731153554051645033698210800'?'newsMenuItem checked':'newsMenuItem'">技术文献</div>
    <!-- <div @click="goOne('20230731153622039169939111575559')" :class="kindId=='20230731153622039169939111575559'?'newsMenuItem checked':'newsMenuItem'">相关标准</div> -->
  </div>
    <div class="wrapper bg-white padding-bottom-30 baseContent">
   
        <div class=" flex justify-between " style="width:100%;">
          <div class="neiBox">
           <div class="neiNewsList">
              <ul>
               <!-- <li class="pointer" v-for="(item,index) in list" :key="index" @click="goNewsDetails(item.id)">
					<div class="flex">
						<div class="neiNewsTime">
							<div class="flex align-center">
								<div class="base-font-40 text-bold text-666" style="border-right:1px solid #eeeeee;padding-right:10px">{{ item.showTime.substr(8,9) }}</div>
								<div class="" style="padding-left:10px;">
									<div class="base-font-16">{{ item.showTime.substr(0,7) }}</div>
									<div class="" style="margin-top:5px;"><i class="el-icon-time"></i></div>
								</div>
							</div>
						</div>
						<div class="neiNewsImage"><el-image :src="item.image"></el-image></div>
						<div class="" style="padding-left:25px;width:700px;">
							<div class="ellipsis base-font-20 text-666 text-bold title" >{{ item.title }}</div>
							<div class=" text-999 ellipsis2 margin-top-20 base-font-15">{{ item.depict }}</div>
							<div class="viewMoreNews base-font-12 text-333">查看详情<i class="el-icon-right"></i></div>
						</div>
					</div>
                </li> -->
				
				<li class="pointer" v-for="(item, index) in list" :key="index" @click="goNewsDetails(item.id)">
				  <div class="flex">
				    <div class="neiNewsImage"><el-image :src="item.image"></el-image></div>
				    <div class="padding-left-15 newsRight">
				      <div class="ellipsis2 base-font-30 text-666 text-bold title">{{ item.title }}</div>
				      <div class=" text-999 ellipsis2 margin-top-20 base-font-20">{{ item.depict }}</div>
									<div class="base-font-20 text-999 margin-top-15">{{ item.showTime.substr(0, 9) }}</div>
				    </div>
				  </div>
				</li>
				
				
              </ul>
					</div>
					<div style="clear:both"></div>
    
            <div class="ttext-center margin-top-40 margin-bottom-20">
              
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNum"
                    :page-sizes="[5, 20, 50, 100]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
              
            </div>
          </div>
        </div>


    </div>


  </div>
</template>

<script>
import InPageImage from '../../components/inPageImage.vue'
import Breadcrumb from '../../components/breadcrumbs.vue'
import {getWzContentPage, menuByUrl} from "@/api/api";
import hotNews from '../news/hotNews'

export default {
  components: {
    Breadcrumb,
    hotNews,
	InPageImage
  },
  data() {
    return {
      leftMenu: {childList: []},
      pathList: [],
      curMenu: {id: ''},
      pageNum: 1,
      pageSize: 5,
      total: 0,
      list: [],
      kindId: ''
    }
  },
  mounted() {
    var kindId = this.$route.query.kindId;
    var path = this.$route.path + "?kindId=" + kindId;
    this.kindId = kindId;
    path = path.replaceAll('/?', '?');
    console.log("path:" + path);
    this.menuByUrl(path);
    this.getWzContentPage();
  },
  methods: {
    menuByUrl(path) {
      menuByUrl(1, path).then(res => {
        this.pathList = res.data.pathList;
        this.leftMenu = res.data.leftMenu;
        this.curMenu = res.data.curMenu;
      })
    },
    goOne(kindId) {
      this.kindId=kindId;
      this.getWzContentPage();
    },
    getWzContentPage() {
      getWzContentPage({pageNum: this.pageNum, pageSize: this.pageSize, kindId: this.kindId}).then(res => {
        this.total = res.count;
        this.list = [];
        res.data.forEach((obj, index) => {
          obj.showTime = this.$moment(obj.clickTime).format('YYYY-MM-DD');
          this.list.push(obj);
        })
      })
    },
    goNewsDetails(id) {
      this.$router.push({path: '/neswDetails?menuId=' + this.curMenu.id + '&id=' + id});
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.getWzContentPage();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getWzContentPage();
    }
  }
}
</script>

<style>
	.viewMoreNews{width:110px;height:30px;background:#e2e5e6;line-height:33px;text-align: center;margin-top:40px;}
	.viewMoreNews i{margin-left: 5px;}
	.viewMoreNews:hover{background: #253783;color:#FFFFFF}
	.neiNewsTime{width:170px; flex-shrink:0;}
	.neiNewsImage{width:280px;height:200px;flex-shrink:0;overflow: hidden;}
	.neiNewsImage .el-image{width:100%;height:100%;-webkit-transform:scale(1);
			    -moz-transform:scale(1);
			    -ms-transfrom:scale(1);
			    -o-transform:scale(1);
			    transform:scale(1);
			transition: transform 0.09s;
	-webkit-transition: transform 0.09s;}
	.newsMenu{padding:15px 0;}
	.newsMenuItem{width:180px;height:48px; border:1px solid #dddddd;margin:30px 10px;text-align: center;line-height:50px;font-size:15px;font-size:16px;cursor: pointer;font-weight:bold;}
	.newsMenuItem.checked,.newsMenuItem:hover{border:1px solid #253783;background:#253783;color:#ffffff;}
.neiBg {
  background: #FFFFFF;
  background-size: cover;
  position: relative;
}



.neiNewsList ul li{padding:30px 0; border-bottom:1px solid #dddddd;width:100%;

}

.neiNewsList ul li:hover .neiNewsImage .el-image{
	-webkit-transform:scale(1.1);
			    -moz-transform:scale(1.1);
			    -ms-transfrom:scale(1.1);
			    -o-transform:scale(1.1);
			    transform:scale(1.1);
			transition: transform 0.2s;
	  -webkit-transition: transform 0.2s;
}

.neiNewsList ul li:hover .title{color:#253783;}

.neiLeftHotMenu {
  margin-bottom: 10px;
}

.neiLeftHotMenu ul li {
  background: none;
  width: 230px;
  margin: 0 auto;
  height: 40px;
  line-height: 40px;
  border: 1px solid #dddddd;
  margin-top: 10px;
  font-size: 14px;;
  padding: 0 10px;
  box-sizing: border-box;
  cursor: pointer;
}

.neiLeftHotMenu ul li:hover {
  border-color: #2d8cdb;
  color: #2d8cdb
}
</style>