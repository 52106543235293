<template>
  <div>
    <div class="neiBaseTit flex justify-center text-white align-center">
      <i class="el-icon-news base-font-20"></i>
      <div class="base-font-20 text-bold margin-left-10">热点资讯</div>
    </div>
    <div class="neiLeftHotMenu">
      <ul>
        <li v-for="(item,index) in menuList" :key="index">
          <div @click="goNewsDetails(item.id)" class="ellipsis">{{ item.title }}</div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>

import {getWzContentPage} from '@/api/api'

export default {
  data() {
    return {
      menuList: [],
    };
  },
  mounted() {
    this.getWzContentPage();
  },
  methods: {
    getWzContentPage() {
      getWzContentPage({pageNum: 1, pageSize: 8}).then(res => {
        this.menuList = res.data;
      })
    },
    goNewsDetails(id) {
      this.$router.push({path: '/neswDetails?menuId=&id=' + id});
    },
  }

}
</script>
<style>
.neiLeftHotMenu{margin-bottom:10px;}
.neiLeftHotMenu ul li{background: none;width:230px;margin:0 auto;height:40px;line-height: 40px;border:1px solid #dddddd;margin-top:10px;font-size:14px;;padding:0 10px;box-sizing: border-box;cursor: pointer;}
.neiLeftHotMenu ul li:hover{border-color:#2d8cdb;color:#2d8cdb}
</style>
